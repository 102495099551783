import * as React from 'react';
import * as assign from 'lodash/assign';
import * as isEmpty from 'lodash/isEmpty';
import { Loader } from '@inwink/loader';
import { motion, MotionProps, AnimationProps } from 'framer-motion';
import { DynLabel, AppTextLabel } from '@@services/i18nservice';
import { DynamicBloc } from './common';
import { BlocTitle } from "./common.title";
import { ContentStyle } from '../contentstyle';
import { InwinkItemTemplate } from '../templates/itemtemplate';
import { AppUserRestrictedComponent } from "../appuser.restricted";
import type { ITeaserItemsPartProps } from './teaseritems.props';
import { WithPopoverManager } from '@inwink/modals/popovermgr';
import { AsyncButton } from '@inwink/buttons';
import { withAppTrads } from '@@components/apptrads';

const networkingTrads = require("@inwink/tradscompanion/networking.json");

const animateItemsInitial: MotionProps["initial"] = __SERVERSIDE__ ? {} : {
    scale: 0.9,
    opacity: 0,
};

const animateItemsAnimate: AnimationProps["animate"] = __SERVERSIDE__ ? {} : {
    scale: 1,
    opacity: 1
};

function stagger(idx: number) {
    const transition: AnimationProps["transition"] = __SERVERSIDE__ ? {} : {
        easings: "easeIn",
        duration: 0.6,
        delay: 0.1 + (Math.min(idx, 16) * 0.1)
    };
    return transition;
}
@withAppTrads(networkingTrads)
class TeaserItemsListContent extends React.Component<ITeaserItemsPartProps, any> {
    itemsContainer = React.createRef<HTMLDivElement>();

    openSeeMoreModal = () => {
        if (this.props.popovermgr) {
            return import('./teaseritems.seemore').then((mod) => {
                return this.props.popovermgr.modalPortal(
                    mod.TeaserItemsSeeMoreModal,
                    this.props,
                    "teaseritems-see-more-modal"
                ).then(() => {
                    if (this.props.onCloseSeeMoreModal) {
                        this.props.onCloseSeeMoreModal();
                    }
                });
            });
        }
    };

    componentDidMount() {
        if (this.itemsContainer.current && !this.props.urlservice.isWidget) {
            const className = this.getItemsContainerClassName();
            if (className !== this.itemsContainer.current.className) {
                this.itemsContainer.current.className = className;
            }
        }
    }

    getItemsContainerClassName() {
        const itemsLayout = this.props.itemsLayout || "ukn";
        const itemsAlign = this.props.itemsAlign || "ukn";
        const itemsResponsiveClassNames = "layout-" + itemsLayout + " align-" + itemsAlign;
        const noitem = !(this.props.items?.length > 0);
        const className = "inwink-items bloc-content " + (noitem ? "noitem " : "") 
            + itemsResponsiveClassNames + " " + this.props.itemsLayoutClassName;
        return className;
    }

    render() {
        let seeMoreAction;
        let itemsComponents;
        let itemstyle;

        if (this.props.items?.length) {
            let items = this.props.items;

            if (this.props.itemtemplate || this.props.onGetItem) {
                let template = this.props.itemtemplate as any;
                if (template && template.template) { // dans ce cas on est sur un IItemTemplateConfig
                    if (template.customCSS) {
                        itemstyle = <ContentStyle
                            css={template.customCSS}
                            blocid={this.props.bloctemplate.id}
                            contentid={this.props.template.id}
                            theme={this.props.theme}
                        />;
                    }

                    template = template.template;
                }
                const editable = this.props.editable && this.props.templateEditable;
                const hasMoreItems = this.props.itemsPreviewCount
                    && items?.length >= this.props.itemsPreviewCount;
                if (hasMoreItems) {
                    items = items.slice(0, this.props.itemsPreviewCount);
                }
                if (this.props.showSeeMoreModal && hasMoreItems) {
                    seeMoreAction = <div className="see-more-container">
                        <AsyncButton onClick={this.openSeeMoreModal}>
                            <AppTextLabel i18n="teaseritems.action.seemore" />
                        </AsyncButton>
                    </div>;
                }

                const animate = this.props.animateItems;

                itemsComponents = items.filter((s) => !!s).map((s, idx) => {
                    if (this.props.onGetItem) {
                        return <div
                            className={"inwink-item " + (this.props.itemClassName ?? "")}
                            key={s.id + "#" + idx}
                            id={('item-' + s.id) || ('#' + idx)}
                        >{this.props.onGetItem(this.props, s)}</div>;
                    }

                    const editData = (data) => {
                        const newItems = assign([], items);
                        newItems[idx] = data;
                        if (this.props.onEditData) {
                            this.props.onEditData(newItems);
                        }
                    };

                    const isClickable = (!!this.props.onItemClick || !!this.props.linkto
                        || !!(s && s.$link && !isEmpty(s.$link)));

                    const staggerIndex = s.$staggerIndex !== undefined
                        ? s.$staggerIndex
                        : idx;

                    return <motion.div
                        initial={animate ? animateItemsInitial : null}
                        animate={animate ? animateItemsAnimate : null}
                        transition={animate ? stagger(staggerIndex) : null}
                        className={"inwink-item " + (this.props.itemClassName ?? "")}
                        key={s.id + "#" + idx}
                        id={('item-' + s.id) || ('#' + idx)}
                    >
                        <InwinkItemTemplate
                            template={template}
                            theme={this.props.theme}
                            page={this.props.page}
                            user={this.props.user.currentUser}
                            i18n={this.props.i18n}
                            data={s}
                            dataKind={this.props.entitySchema?.entityName || this.props.dataKind}
                            link={s && s.$link}
                            datacontext={this.props.datacontext}
                            className={"itemcontent" + (isClickable ? " clickable" : "")}
                            onClick={this.props.onItemClick}
                            linkto={this.props.linkto}
                            urlservice={this.props.urlservice}
                            contentEditable={editable}
                            onEdit={(t) => console.log(t)}
                            onEditData={editData}
                            onDataUpdate={(data) => this.props.onDataUpdate(data)}
                        />
                    </motion.div>;
                });
            }
        } else if (this.props.loading) {
            itemsComponents = <div key="message" className="message">
                <Loader />
            </div>;
        } else if (this.props.emptyComponent) {
            itemsComponents = this.props.emptyComponent;
        } else if (this.props.emptyMessage) {
            if (typeof this.props.emptyMessage === "string") {
                itemsComponents = <div key="message" className="message">
                    <AppTextLabel i18n={this.props.emptyMessage} defaultText={this.props.emptyMessage} />
                </div>;
            } else {
                itemsComponents = <div key="message" className="message">
                    <DynLabel i18n={this.props.emptyMessage} />
                </div>;
            }
        }

        let itemsStyle = null;

        if (this.props.template?.properties?.listItemsAlign) {
            itemsStyle = { justifyContent: this.props.template.properties.listItemsAlign };
        }

        let itemsResponsiveCSS;
        if (this.props.urlservice?.isWidget) {
            // eslint-disable-next-line react/no-danger
            itemsResponsiveCSS = <style dangerouslySetInnerHTML={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                __html: this.props.itemsAlignCSS + this.props.itemsLayoutCSS
            }}
            />;
        }

        const itemsContainerClassName = this.getItemsContainerClassName();

        let content = <div
            ref={this.itemsContainer}
            className={itemsContainerClassName}
            style={itemsStyle}
        >
            {itemsComponents}
            {seeMoreAction}
        </div>;

        if (this.props.restritedContent) {
            const allowNetworking = this.props.restritedContent === 'Login_Registered_AllowNetworking';
            content = <AppUserRestrictedComponent {...this.props} checkAllowNetworking={allowNetworking}>
                {content}
            </AppUserRestrictedComponent>;
        }

        if (this.props.renderOnlyContent) {
            return <div className={"itemslist " + (this.props.className || "")}>
                {itemstyle}
                {content}
            </div>;
        }

        return <DynamicBloc {...this.props} className={"itemslist " + (this.props.className || "")}>
            {itemstyle}
            {itemsResponsiveCSS}
            <BlocTitle {...this.props} className="bloc-header" />
            {content}
        </DynamicBloc>;
    }
}

export function TeaserItemsList(props: ITeaserItemsPartProps) {
    return (<WithPopoverManager>
        {(popovermgr) => <TeaserItemsListContent {...props} popovermgr={popovermgr} />}
    </WithPopoverManager>);
}