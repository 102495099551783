import * as React from 'react';
import * as assign from 'lodash/assign';
import * as isEmpty from 'lodash/isEmpty';
import Slider from 'react-slick';
import { visualStates } from "@@services/itemshelpers";
import { DynamicBloc } from './common';
import { BlocTitle } from "./common.title";
import { ContentStyle } from '../contentstyle';
import { InwinkItemTemplate } from '../templates/itemtemplate';
import { AppUserRestrictedComponent } from "../appuser.restricted";
import { InView } from '../useinview/InView';
import type { ITeaserItemsPartProps } from './teaseritems.props';
import './teaseritems.carousel.less';

interface IItemsSliderProps extends Omit<ITeaserItemsPartProps, 'children'> {
    item?: any;
    className: string;
    children?: (settings: any, itemHeight: any) => React.ReactNode;
}

function PrevArrow(props: any) {
    return <div className={props.className} style={props.style} data-role={props["data-role"]}>
        <button type="button" className="inwink-chevron-left" />
    </div>;
}

function NextArrow(props: any) {
    return <div className={props.className} style={props.style} data-role={props["data-role"]}>
        <button type="button" className="inwink-chevron-right" />
    </div>;
}

class ItemsSliderMeasure extends React.Component<IItemsSliderProps, any> {
    constructor(props: IItemsSliderProps) {
        super(props);
        const prevArrowComponent = PrevArrow(this.props);
        const nextArrowComponent = NextArrow(this.props);
        const sliderSettings = {...this.props.template.properties.sliderSettings};

        if (sliderSettings.transitionStyle) {
            delete sliderSettings.fade;
            delete sliderSettings.slide;
            sliderSettings[sliderSettings.transitionStyle] = true;
        }

        const { slidesToShow, slidesToScroll } = this.setCarouselSlidesToShow(this.props.template.properties);

        this.state = {
            displayDots: true,
            settings: assign(
                {
                    dots: false,
                    infinite: true,
                    speed: 500,
                    pauseOnHover: false,
                    slidesToShow,
                    slidesToScroll,
                    lazyLoad: true,
                    swipeToSlide: true
                },
                sliderSettings,
                {
                    prevArrow: prevArrowComponent,
                    nextArrow: nextArrowComponent
                }
            )
        };
        // if (this.props.template.properties.responsiveCarousel) {
        //     this.state.settings.responsive = this.props.template.properties.responsiveCarousel;
        // }
    }

    // setCarouselDots = () => {
    //     // eslint-disable-next-line react/no-find-dom-node
    //     const node = ReactDOM.findDOMNode(this) as HTMLElement;
    //     const numberOfDots = node?.querySelectorAll('.slick-dots > li')?.length;
    //     const displayDots = numberOfDots < 30;

    //     this.setState({ displayDots });
    // };

    setCarouselSlidesToShow = (properties: any) => {
        const tmpvisualstate = this.props.visualstate ? [...this.props.visualstate] : [];
        if (!(tmpvisualstate.indexOf("XXS") >= 0)) {
            tmpvisualstate.push("XXS");
        }
        const template = properties;
        let slidesToShow = this.props.defaultItemsLayout;
        if (template?.itemsLayout) { slidesToShow = template.itemsLayout; }

        let slidesToShowCompute = '3'; // Default

        if (slidesToShow && typeof slidesToShow !== 'string') {
            if (slidesToShow.default) { slidesToShowCompute = slidesToShow.default; }

            visualStates.forEach((s) => {
                if (slidesToShow[s]) {
                    if (tmpvisualstate && tmpvisualstate.indexOf(s) >= 0) {
                        slidesToShowCompute = slidesToShow[s];
                    }
                }
            });
        }

        slidesToShow = parseInt(slidesToShowCompute, 10);
        if (Number.isNaN(slidesToShow)) {
            if (slidesToShowCompute === "floating") {
                slidesToShowCompute = "1";
            } else {
                slidesToShowCompute = slidesToShowCompute.replace('col', '');
            }
            slidesToShow = parseInt(slidesToShowCompute, 10);
        }

        const slidesToScroll = slidesToShow;
        return { slidesToShow, slidesToScroll };
    };

    componentDidUpdate(prevProps) {
        if (
            (this.props.visualstate !== prevProps.visualstate)
            || ((this.props.template?.properties) !== (prevProps.template?.properties))
        ) {
            const { slidesToShow, slidesToScroll } = this.setCarouselSlidesToShow(this.props.template.properties);

            this.setState((prevState) => {
                return {
                    settings: {...prevState.settings, slidesToShow, slidesToScroll }
                };
            });
        }
    }

    onResize = () => {
        // this.setCarouselDots();
        // this.measure();
    };

    /*
    measure() {
        // eslint-disable-next-line react/no-find-dom-node
        const node = ReactDOM.findDOMNode(this) as HTMLElement;
        const item = node.querySelector('.bloc-carousel-measure > .' + this.props.itemClassName);
        if (item) {
            const itemSize = item.getBoundingClientRect();
            const carousel = node.querySelector('.slick-slider .slick-list');
            if (carousel) {
                const carouselSize = carousel.getBoundingClientRect();
                let nbitems = Math.floor(carouselSize.width / (itemSize.width + 10));
                if (nbitems <= 0) {
                    nbitems = 1;
                }

                if (nbitems !== this.state.settings.slidesToShow || itemSize.height !== this.state.itemHeight) {
                    this.setState((prevState) => {
                        return {
                            itemHeight: itemSize.height,
                            settings: assign({}, prevState.settings, {
                                slidesToShow: nbitems,
                                slidesToScroll: nbitems,
                            })
                        };
                    });
                }
            }
        }
    }
    */

    componentDidMount() {
        // this.measure();
        // this.setCarouselDots();

        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    render() {
        const children = this.props.children as any;
        const content = children(this.state.settings, this.state.itemHeight);
        let measure;
        if (this.props.items && this.props.items.length) {
            let template = this.props.itemtemplate as any;
            if (template && template.template) { // dans ce cas on est sur un IItemTemplateConfig
                template = template.template;
            }
            const itemdata = this.props.items[0];
            measure = <div className={"inwink-item carouselitem " + (this.props.itemClassName ?? "")}>
                <InwinkItemTemplate
                    template={template}
                    theme={this.props.theme}
                    page={this.props.page}
                    user={this.props.user.currentUser}
                    i18n={this.props.i18n}
                    data={itemdata}
                    dataKind={this.props.entitySchema?.entityName}
                    link={itemdata && itemdata.$link}
                    datacontext={this.props.datacontext}
                    className="itemcontent"
                    onClick={this.props.onItemClick}
                    linkto={this.props.linkto}
                    urlservice={this.props.urlservice}
                />
            </div>;
        }

        // const style: any = { paddingBottom: 80 };
        // if (!this.state.displayDots) {
        //     style.paddingBottom = 0;
        // }

        return <div className={"bloc-content bloc-carousel " + this.props.className}>
            <div className={"bloc-carousel-measure " + this.props.className}>
                {measure}
            </div>
            {content}
        </div>;
    }
}

export function TeaserItemsCarousel(props: ITeaserItemsPartProps) {
    let itemsComponents;
    let itemstyle;
    const isDefered = props.template?.properties?.useDeferedRendering;

    if (props.items) {
        const items = props.items;

        if (props.itemtemplate || props.onGetItem) {
            let template = props.itemtemplate as any;
            if (template && template.template) { // dans ce cas on est sur un IItemTemplateConfig
                if (template.customCSS) {
                    itemstyle = <ContentStyle
                        css={template.customCSS}
                        blocid={props.bloctemplate.id}
                        contentid={props.template.id}
                        theme={props.theme}
                    />;
                }

                template = template.template;
            }

            const editable = props.editable && props.templateEditable;
            itemsComponents = items.filter((s) => !!s).map((s, idx) => {
                return <CarouselItem
                    {...props}
                    key={"item" + idx + s.id}
                    carouselItemTemplate={template}
                    carouselItems={items}
                    item={s}
                    itemIndex={idx}
                    defered={isDefered}
                    editable={editable}
                />;
            });
        }
    }

    let content = <ItemsSliderMeasure {...props} className="inwink-items carousel">
        {
            // eslint-disable-next-line react/no-children-prop
            (carouselsettings) => <Slider {...carouselsettings} children={itemsComponents} />
        }
    </ItemsSliderMeasure>;
    if (props.restritedContent) {
        const allowNetworking = props.restritedContent === 'Login_Registered_AllowNetworking';
        content = <AppUserRestrictedComponent {...props} checkAllowNetworking={allowNetworking}>
            {content}
        </AppUserRestrictedComponent>;
    }

    return <DynamicBloc {...props} className={"carousel " + (props.className || "")}>
        {itemstyle}
        <BlocTitle {...props} className="bloc-header" />
        {content}
        <div className="iw-clearfix" />
    </DynamicBloc>;
}

interface ICarouselItemProps extends ITeaserItemsPartProps {
    item: any;
    itemIndex: number;
    editable: boolean;
    defered: boolean;
    carouselItemTemplate: any;
    carouselItems: any;
    style?: any; // injected by carousel
}

function CarouselItem(props: ICarouselItemProps) {
    if (props.onGetItem) {
        return <div
            className={"inwink-item carouselitem " + (props.itemClassName ?? "")}
            id={('item-' + props.item.id) || ('#' + props.itemIndex)}
        >{props.onGetItem(props, props.item)}</div>;
    }

    const editData = (data) => {
        const newItems = assign([], props.carouselItems);
        newItems[props.itemIndex] = data;
        if (props.onEditData) {
            props.onEditData(newItems);
        }
    };

    const isClickable = (!!props.onItemClick
        || !!props.linkto
        || !!(props.item && props.item.$link && !isEmpty(props.item.$link)));

    if (props.defered && !__SERVERSIDE__) {
        return <div className={"carouselitem " + (props.itemClassName ?? "")} style={props.style}>
            <InView triggerOnce={true}>
                {({ inView, ref}) => <div
                    ref={ref}
                    className={"inwink-item " + (props.itemClassName ?? "")}
                    id={('item-' + props.item.id) || ('#' + props.itemIndex)}
                >
                    {inView
                        ? <CarouselItemTemplate {...props} isClickable={isClickable} editData={editData} />
                        : null}
                </div>}
            </InView>
        </div>;
    }

    return <div className={"carouselitem " + (props.itemClassName ?? "")} style={props.style}>
        <div className={"inwink-item " + (props.itemClassName ?? "")} id={('item-' + props.item.id) || ('#' + props.itemIndex)}>
            <CarouselItemTemplate {...props} isClickable={isClickable} editData={editData} />
        </div>
    </div>;
}

interface ICarouselItemTemplateProps extends ICarouselItemProps {
    isClickable: boolean;
    editData: any;
}

function CarouselItemTemplate(props: ICarouselItemTemplateProps) {
    return <InwinkItemTemplate
        template={props.carouselItemTemplate}
        theme={props.theme}
        page={props.page}
        user={props.user.currentUser}
        i18n={props.i18n}
        data={props.item}
        dataKind={props.entitySchema?.entityName || props.dataKind}
        link={props.item?.$link}
        datacontext={props.datacontext}
        className={"itemcontent" + (props.isClickable ? " clickable" : "")}
        onClick={props.onItemClick}
        linkto={props.linkto}
        urlservice={props.urlservice}
        contentEditable={props.editable}
        onEdit={(t) => console.log(t)}
        onEditData={props.editData}
    />;
}
